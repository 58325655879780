<template>
  <div id="app">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>
<script>
import HomeLayout from "./layouts/HomeLayout.vue";
import OtrosLayout from "./layouts/OtrosLayout.vue";
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "home") + "-layout";
    },
  },
  components: {
    HomeLayout,
    OtrosLayout,
  },
};
</script>

<style lang="scss">
* {
  margin: 0px;
  padding: 0px;
}
// @font-face {
//   font-family: "Jost-Regular";
//   src: url("./assets/fonts/Jost-Regular.ttf") format("truetype");
//   font-style: normal;
//   font-weight: normal;
// }
// @font-face {
//   font-family: "Jost-Light";
//   src: url("./assets/fonts/Jost-Light.ttf") format("truetype");
//   font-style: normal;
//   font-weight: normal;
// }
// @font-face {
//   font-family: "Jost-Bold";
//   src: url("./assets/fonts/Jost-Bold.ttf") format("truetype");
//   font-style: normal;
//   font-weight: normal;
// }
@font-face {
  font-family: "SF-Regular";
  src: url("./assets/fonts/SF-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "SF-Light";
  src: url("./assets/fonts/SF-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "SF-Bold";
  src: url("./assets/fonts/SF-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@media (min-width: 416px) {
  .containerlayout {
    font-family: Jost-Regular;
    display: grid;
    grid-template-columns: minmax(10px, auto) minmax(100px, 1442px) minmax(
        10px,
        auto
      );
    grid-template-rows: minmax(182px, auto);
  }
  .container {
    grid-column: 2;
  }
}
@media (max-width: 415px) {
  .containerlayout {
    font-family: Jost-Regular;
    display: grid;
    grid-template-columns: minmax(auto, 400px);
    grid-template-rows: minmax(182px, auto);
    justify-content: center;
  }
}
</style>

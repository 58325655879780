<template>
  <div class="footer">
    <img class="logofooter" src="../assets/home/logofooter.png" alt="" />
    <div class="menufooter">
      <ul>
        <li class="li1">
          <router-link to="/deyatelnost">Деятельность</router-link>
        </li>
        <li class="li2">
          <router-link to="/history">История</router-link>
        </li>
        <li class="li3">
          <router-link to="/vacancies">Вакансии</router-link>
        </li>
        <li class="li4">
          <router-link to="/students">Студентам</router-link>
        </li>
        <li class="li5">
          <router-link to="/partners">Партнерам</router-link>
        </li>
        <li class="li6">
          <router-link to="/contacts">Контакты</router-link>
        </li>
      </ul>
    </div>
    <a href="https://vk.com/agropobeda" target="_blank">
      <img class="vk" src="../assets/home/vk.png" alt="" />
    </a>
    <div class="politica">Политика конфиденциальности</div>
    <div class="allrights">© 2021 - 2023 · Все права защищены</div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
@media (max-width: 415px) {
  .footer {
    background-color: #a1ad8f;
    display: grid;
    grid-template-columns: minmax(auto, 764px);
    grid-template-rows: repeat(5, auto);
    row-gap: 47px;
    justify-content: center;
    justify-items: center;
    .logofooter {
      margin-top: 47px;
      width: 148px;
    }
    .vk {
      width: 30px;
    }
    .politica {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      text-decoration-line: underline;
      color: white;
    }
    .allrights {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: white;
      margin-bottom: 54px;
    }
    .menufooter ul {
      display: grid;
      grid-template-rows: repeat(6, 1fr);
      justify-items: center;
      row-gap: 20px;
      li {
        list-style-type: none;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
@media (min-width: 416px) {
  .footer {
    background-color: #a1ad8f;
    display: grid;
    grid-template-columns: minmax(auto, 764px);
    grid-template-rows: repeat(5, auto);
    row-gap: 47px;
    justify-content: center;
    justify-items: center;
    .logofooter {
      margin-top: 47px;
      width: 148px;
    }
    .vk {
      width: 30px;
    }
    .politica {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      text-decoration-line: underline;
      color: white;
    }
    .allrights {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: white;
      margin-bottom: 54px;
    }
    .menufooter ul {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      justify-items: center;
      li {
        list-style-type: none;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        a {
          color: white;
        }
      }
    }
  }
}
</style>

<template>
  <div class="menu">
    <ul>
      <li class="li1">
        <router-link to="/deyatelnost">Деятельность</router-link>
      </li>
      <li class="li2">
        <router-link to="/history">История</router-link>
      </li>
      <li class="li3">
        <router-link to="/vacancies">Вакансии</router-link>
      </li>
      <li class="li4">
        <router-link to="/students">Студентам</router-link>
      </li>
      <li class="li5">
        <router-link to="/partners">Партнерам</router-link>
      </li>
      <li class="li6">
        <router-link to="/contacts">Контакты</router-link>
      </li>
      <li class="li7">
        <router-link to="/">
          <img src="../assets/logo.png" alt=""
        /></router-link>
      </li>
    </ul>
  </div>
  <div class="menumobile">
    <div class="hamburger-menu">
      <div
        v-if="hideNohide == true"
        class="hide"
        @click="hideNohide = !hideNohide"
      >
        <img src="../assets/hamburger.png" alt="" />
      </div>
      <div v-else class="nohide" @click="hideNohide = !hideNohide">
        <img class="closehamburger" src="../assets/close.png" alt="" />
        <ul>
          <li class="li1">
            <router-link to="/deyatelnost">Деятельность</router-link>
          </li>
          <li class="li2">
            <router-link to="/history">История</router-link>
          </li>
          <li class="li3">
            <router-link to="/vacancies">Вакансии</router-link>
          </li>
          <li class="li4">
            <router-link to="/students">Студентам</router-link>
          </li>
          <li class="li5">
            <router-link to="/partners">Партнерам</router-link>
          </li>
          <li class="li6">
            <router-link to="/contacts">Контакты</router-link>
          </li>
        </ul>
      </div>
    </div>
    <router-link class="logo" to="/">
      <img src="../assets/logo.png" alt=""
    /></router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hideNohide: true,
    };
  },
};
</script>
<style lang="scss">
@media (max-width: 415px) {
  .menumobile {
    display: grid;
    grid-template-columns: minmax(auto, 50px) 1fr minmax(auto, 50px);
    grid-template-rows: minmax(156px, 156px);
    margin-top: 40px;
    .logo {
      grid-column: 2;
      display: flex;
      justify-content: center;
      img {
        width: 100px;
        height: 124px;
        object-fit: cover;
      }
    }
    .hamburger-menu {
      .hide {
        padding: 15px 0 0 15px;
      }
      .nohide {
        background-color: white;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 101;
        .closehamburger {
          padding: 15px 0 0 15px;
        }
        ul {
          list-style-type: none;
          li {
            margin: 15px 0 0 15px;
            font-size: 24px;
            a {
              color: black;
            }
          }
        }
      }
    }
  }
  .menu {
    display: none;
  }
}
@media (min-width: 416px) {
  .menumobile {
    display: none;
  }
  .menu ul {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: minmax(182px, 182px);
    align-items: center;
    justify-items: center;
  }
  .menu ul li {
    list-style-type: none;
  }
  .menu .li1 {
    grid-column: 2;
  }
  .menu .li2 {
    grid-column: 3;
  }
  .menu .li3 {
    grid-column: 4;
  }
  .menu .li4 {
    grid-column: 6;
  }
  .menu .li5 {
    grid-column: 7;
  }
  .menu .li6 {
    grid-column: 8;
  }
  .menu .li7 {
    grid-column: 5;
    grid-row: 1;
  }
  .menu .li7 img {
    object-fit: cover;
    width: 100px;
  }
  a {
    text-decoration: none;
    color: #231f20;
  }
}
</style>

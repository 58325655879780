<template>
  <div class="containerlayout">
    <div class="container">
      <Header />
      <!-- 1 -->
      <div class="bg">
        <div class="placeholder">
          <div class="title">ПОБЕДА</div>
          <div class="subtitle">АГРОФИРМА</div>
        </div>
      </div>
      <!-- 2 -->
      <div class="about">
        <div class="placeholder">
          <div class="left"></div>
          <div class="text">
            <div class="title">О КОМПАНИИ</div>
            <div class="subtitle">
              {{ about }}
            </div>
            <div class="button">
              <button>
                <router-link to="/history">Подробнее</router-link>
              </button>
            </div>
          </div>
          <div class="fotoleft">
            <img class="about1" src="../assets/home/about1.png" alt="" />
            <img class="about2" src="../assets/home/about2.png" alt="" />
            <img class="about2bg" src="../assets/home/about2bg.png" alt="" />
          </div>
          <div class="fotoright">
            <img class="about3" src="../assets/home/about3.png" alt="" />
            <img class="about3bg" src="../assets/home/about3bg.png" alt="" />
            <img class="about4" src="../assets/home/about4.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div class="stat">
        <div class="placeholder">
          <div class="line1">
            <img class="stat1" src="../assets/home/stat1.png" alt="" />
            <img class="stat2" src="../assets/home/stat2.png" alt="" />
            <img class="stat3" src="../assets/home/stat3.png" alt="" />
            <img class="stat4" src="../assets/home/stat4.png" alt="" />
          </div>
          <div class="line2">
            <div class="stat1">
              <div class="title">{{ stat1 }}</div>
              <div class="subtitle">{{ stat1_sub }} <br />&nbsp;</div>
            </div>
            <div class="stat2">
              <div class="title" style="display: grid; justify-items: center">
                {{ stat2 }}
              </div>
              <div class="subtitle" style="max-width: 200px">
                {{ stat2_sub }}
              </div>
            </div>
            <div class="stat3">
              <div class="title">{{ stat3 }}</div>
              <div class="subtitle">
                {{ stat3_sub }} <br />
                &nbsp;
              </div>
            </div>
            <div class="stat4">
              <div class="title">{{ stat4 }}</div>
              <div class="subtitle">
                {{ stat4_sub }}
                <br />
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 4 -->
      <div class="deyatelnost">
        <div class="title">ДЕЯТЕЛЬНОСТЬ</div>
        <div class="card1">
          <div class="title">ЖИВОТНОВОДСТВО</div>
        </div>
        <div class="card2">
          <div class="title">ПРОИЗВОДСТВО СЫРОГО МОЛОКА</div>
        </div>
        <div class="card3">
          <div class="title">РАСТЕНИЕВОДСТВО</div>
        </div>
      </div>
      <!-- 6 -->
      <div class="nagradi">
        <div class="title">НАГРАДЫ</div>
        <div class="diploms">
          <img src="../assets/home/diplom1.png" alt="" />
          <img src="../assets/home/diplom2.png" alt="" />
          <img src="../assets/home/diplom3.png" alt="" />
          <img src="../assets/home/diplom4.png" alt="" />
          <img src="../assets/home/diplom5.png" alt="" />
          <img src="../assets/home/diplom6.png" alt="" />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  data() {
    return {
      about: "",
      stat1: "",
      stat1_sub: "",
      stat2: "",
      stat2_sub: "",
      stat3: "",
      stat3_sub: "",
      stat4: "",
      stat4_sub: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cd20321.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.about = finalRes[0].text;
      this.stat1 = finalRes[1].text;
      this.stat1_sub = finalRes[2].text;
      this.stat2 = finalRes[3].text;
      this.stat2_sub = finalRes[4].text;
      this.stat3 = finalRes[5].text;
      this.stat3_sub = finalRes[6].text;
      this.stat4 = finalRes[7].text;
      this.stat4_sub = finalRes[8].text;
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 415px) {
  .bg {
    background-image: url("../assets/home/gbm.png");
    background-size: cover;
    .placeholder {
      display: grid;
      grid-template-columns: minmax(auto, 375px);
      grid-template-rows: minmax(auto, 120px) minmax(auto, 496px);
      justify-items: center;
      .title {
        grid-row: 1;
        font-weight: 600;
        font-size: 60px;
        line-height: 87px;
        align-self: flex-end;
      }
      .subtitle {
        grid-row: 2;
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
      }
    }
  }
  .about .placeholder {
    display: grid;
    grid-template-columns: minmax(auto, 335px);
    grid-template-rows: repeat(4, auto);
    row-gap: 24px;
    margin-top: 36px;
    justify-content: center;
    .left {
      display: none;
    }
    .text {
      grid-row: 1;
      display: grid;
      row-gap: 24px;
      justify-items: center;
      margin-bottom: 24px;
      .title {
        font-weight: 500;
        font-size: 32px;
        line-height: 46px;
        grid-row: 1;
      }
      .subtitle {
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
      }
      .button button {
        width: 201px;
        height: 60px;
        background: linear-gradient(0deg, #bac8a5 0%, #e1edd0 100%);
        box-shadow: 0px 40px 60px rgba(184, 197, 166, 0.5);
        border-radius: 5px;
        border: 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-transform: uppercase;
        a {
          color: #231f20;
          text-decoration: none;
        }
      }
    }
    .fotoleft {
      display: grid;
      grid-template-columns: minmax(auto, 374px);
      grid-template-rows: minmax(auto, auto) minmax(374px, auto) minmax(
          auto,
          auto
        );
      align-self: center;
      justify-items: end;
      .about1 {
        object-fit: cover;
        grid-row: 1;
        justify-self: flex-start;
        width: 268px;
      }
      .about2 {
        object-fit: cover;
        grid-row: 2;
        grid-column: 1;
        width: 268px;
        transform: scale(-1, 1);
      }
      .about2bg {
        object-fit: cover;
        grid-row: 2;
        grid-column: 1;
        width: 302px;
        z-index: -1;
        transform: rotate(-90deg);
      }
    }
    .fotoright {
      grid-column: 1;
      grid-row: 3;
      display: grid;
      grid-template-columns: minmax(auto, 375px);
      grid-template-rows: repeat(2, auto);
      .about3 {
        object-fit: cover;
        grid-row: 1;
        grid-column: 1;
        align-self: flex-end;
        width: 268px;
        transform: scale(-1, 1);
      }
      .about3bg {
        object-fit: cover;
        grid-row: 2;
        grid-column: 1;
        width: 302px;
        z-index: -1;
        align-self: flex-end;
        justify-self: flex-end;
        transform: rotate(90deg);
      }
      .about4 {
        object-fit: cover;
        grid-row: 2;
        grid-column: 1;
        width: 268px;
        align-self: flex-start;
        justify-self: flex-end;
      }
    }
  }
  .stat {
    background-color: #d9e1cd;
    .title {
      font-weight: 500;
      font-size: 32px;
      line-height: 46px;
      text-align: center;
    }
    .subtitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
    }
    img {
      width: 90px;
      object-fit: cover;
    }
    .placeholder {
      display: grid;
      grid-template-columns: minmax(auto, 375px);
      grid-template-rows: repeat(8, 1fr);
      justify-content: center;
      justify-items: center;
      align-items: center;
      row-gap: 20px;
      padding: 85px 0;
      .line1 {
        display: contents;
        .stat1 {
          grid-row: 1;
          grid-column: 1;
        }
        .stat2 {
          grid-row: 3;
          grid-column: 1;
        }
        .stat3 {
          grid-row: 5;
          grid-column: 1;
        }
        .stat4 {
          grid-row: 7;
          grid-column: 1;
        }
      }
      .line2 {
        display: contents;
        .stat1 {
          grid-row: 2;
          grid-column: 1;
        }
        .stat2 {
          grid-row: 4;
          grid-column: 1;
        }
        .stat3 {
          grid-row: 6;
          grid-column: 1;
        }
        .stat4 {
          grid-row: 8;
          grid-column: 1;
        }
      }
    }
  }
  .deyatelnost {
    display: grid;
    grid-template-columns: minmax(auto, auto);
    grid-template-rows: minmax(auto, 70px) repeat(3, 391px);
    margin: 60px 0;
    .title {
      font-weight: 500;
      font-size: 32px;
      line-height: 46px;
      text-align: center;
    }
    .card1 {
      background-image: url("../assets/home/deyatelnost1m.png");
      background-size: cover;
      z-index: -1;
      .title {
        color: white;
        padding-top: 30px;
        padding-left: 20px;
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        text-align: left;
      }
    }
    .card2 {
      background-image: url("../assets/home/deyatelnost2m.png");
      background-size: cover;
      z-index: -1;
      .title {
        color: white;
        padding-top: 30px;
        padding-left: 20px;
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        text-align: left;
      }
    }
    .card3 {
      background-image: url("../assets/home/deyatelnost3m.png");
      background-size: cover;
      z-index: -1;
      .title {
        color: white;
        padding-top: 30px;
        padding-left: 20px;
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        text-align: left;
      }
    }
  }
  .nagradi {
    display: grid;
    grid-template-columns: minmax(auto, auto);
    grid-template-rows: minmax(auto, auto) minmax(auto, auto);
    row-gap: 30px;
    .title {
      font-weight: 500;
      font-size: 32px;
      line-height: 46px;
      text-align: center;
    }
    .diploms {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      justify-items: center;
      img {
        width: 160px;
      }
    }
  }
}
@media (min-width: 416px) {
  .nagradi {
    display: grid;
    grid-template-columns: minmax(auto, auto);
    grid-template-rows: minmax(auto, 252px) minmax(auto, 1100px);
    row-gap: 30px;
    .title {
      font-weight: 500;
      font-size: 50px;
      line-height: 72px;
      align-self: center;
      padding-left: 129px;
      align-self: flex-end;
    }
    .diploms {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      justify-items: center;
      img {
        width: 373px;
      }
    }
  }
  .deyatelnost {
    display: grid;
    grid-template-columns: minmax(auto, auto);
    grid-template-rows: minmax(auto, 252px) repeat(3, 650px);
    row-gap: 50px;
    .title {
      font-weight: 500;
      font-size: 50px;
      line-height: 72px;
      align-self: flex-end;
      padding-left: 129px;
    }
    .card1 {
      background-image: url("../assets/home/deyatelnost1.png");
      background-size: cover;
      z-index: -1;
      .title {
        color: white;
        padding-top: 100px;
      }
    }
    .card2 {
      background-image: url("../assets/home/deyatelnost2.png");
      background-size: cover;
      z-index: -1;
      .title {
        color: white;
        padding-top: 100px;
      }
    }
    .card3 {
      background-image: url("../assets/home/deyatelnost3.png");
      background-size: cover;
      z-index: -1;
      .title {
        color: white;
        padding-top: 100px;
      }
    }
  }
  .stat {
    background-color: #d9e1cd;
    .title {
      font-weight: 500;
      font-size: 50px;
      line-height: 72px;
    }
    .subtitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
    }
    img {
      width: 90px;
      object-fit: cover;
    }
    .placeholder {
      display: grid;
      grid-template-columns: minmax(auto, 1250px);
      grid-template-rows: minmax(auto, 210px) minmax(auto, 236px);
      justify-items: stretch;
      justify-content: center;
      .line1 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: minmax(auto, 210px);
        align-items: end;
        justify-items: center;
        align-self: flex-end;
      }
      .line2 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: minmax(auto, 220px);
        align-items: flex-start;
        justify-items: center;
      }
    }
  }
  .about .placeholder {
    display: grid;
    grid-template-columns:
      minmax(auto, 131px) minmax(auto, 478px) minmax(auto, 374px)
      minmax(auto, 374px);
    grid-template-rows: minmax(auto, 948px);
    justify-items: center;
    justify-content: space-between;
    .left {
      grid-column: 1;
    }
    .text {
      grid-column: 2;
      align-self: center;
      .title {
        font-weight: 500;
        font-size: 50px;
        line-height: 72px;
      }
      .subtitle {
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        margin: 40px 0;
      }
      .button button {
        width: 201px;
        height: 60px;
        background: linear-gradient(0deg, #bac8a5 0%, #e1edd0 100%);
        box-shadow: 0px 40px 60px rgba(184, 197, 166, 0.5);
        border-radius: 5px;
        border: 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-transform: uppercase;
      }
    }
    .fotoleft {
      grid-column: 3;
      display: grid;
      grid-template-columns: minmax(auto, 374px);
      grid-template-rows: minmax(auto, auto) minmax(374px, auto) minmax(
          auto,
          auto
        );
      align-self: center;
      justify-items: end;
      .about1 {
        object-fit: cover;
        grid-row: 1;
        align-self: flex-end;
        width: 268px;
      }
      .about2 {
        object-fit: cover;
        grid-row: 2;
        grid-column: 1;
        width: 268px;
      }
      .about2bg {
        object-fit: cover;
        grid-row: 2;
        grid-column: 1;
        width: 374px;
        z-index: -1;
      }
    }
    .fotoright {
      grid-column: 4;
      display: grid;
      grid-template-columns: minmax(auto, 374px);
      grid-template-rows: minmax(auto, auto) minmax(auto, 374) minmax(
          auto,
          auto
        );
      align-self: center;
      justify-items: start;
      .about3 {
        object-fit: cover;
        grid-row: 2;
        grid-column: 1;
        align-self: flex-end;
        width: 268px;
      }
      .about3bg {
        object-fit: cover;
        grid-row: 2;
        grid-column: 1;
        width: 374px;
        z-index: -1;
        align-self: flex-end;
      }
      .about4 {
        object-fit: cover;
        grid-row: 3;
        grid-column: 1;
        width: 268px;
        align-self: flex-start;
      }
    }
  }
  .bg {
    background-image: url("../assets/home/gb.png");
    background-size: cover;
    .placeholder {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: minmax(auto, 185px) minmax(auto, 533px);
      justify-items: center;
      .title {
        grid-column: 2;
        grid-row: 1;
        font-weight: 600;
        font-size: 100px;
        line-height: 144px;
        align-self: flex-end;
      }
      .subtitle {
        grid-column: 2;
        grid-row: 2;
        font-weight: 500;
        font-size: 50px;
        line-height: 72px;
      }
    }
  }
}
</style>

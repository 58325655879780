import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { layout: "home" },
    component: () => import("../layouts/HomeLayout.vue"),
  },
  {
    path: "/history",
    name: "History",
    meta: { layout: "otros" },
    component: () => import("../views/History.vue"),
  },
  {
    path: "/deyatelnost",
    name: "Deyatelnost",
    meta: { layout: "otros" },
    component: () => import("../views/Deyatelnost.vue"),
  },
  {
    path: "/vacancies",
    name: "Vacancies",
    meta: { layout: "otros" },
    component: () => import("../views/Vacancies.vue"),
  },
  {
    path: "/students",
    name: "Students",
    meta: { layout: "otros" },
    component: () => import("../views/Students.vue"),
  },
  {
    path: "/partners",
    name: "Partners",
    meta: { layout: "otros" },
    component: () => import("../views/Partners.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    meta: { layout: "otros" },
    component: () => import("../views/Contacts.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

export default router;

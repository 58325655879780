import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueYandexMetrika from "vue-v3-yandex-metrika";

// createApp(App).use(router).mount("#app");

const app = createApp(App);
app.use(router);
app.use(VueYandexMetrika, {
  id: 88405743,
  router: router,
  env: process.env.NODE_ENV,
  // other options
});
app.mount("#app");
